// src/components/PhoneToggle/PhoneToggle.js
import React from 'react';
import { Link } from 'react-router-dom';
import './PhoneToggle.css';

const PhoneToggle = ({ activeLink, toggleMenu }) => {
  return (
    <div className="phone-toggle">
      <ul className="phone-nav">
        <li className={`phone-nav-item ${activeLink === '/' ? 'active' : ''}`}>
          <Link to="/" className="phone-nav-link" onClick={toggleMenu}>Home</Link>
        </li>
        <li className={`phone-nav-item ${activeLink === '/gallery' ? 'active' : ''}`}>
          <Link to="/gallery" className="phone-nav-link" onClick={toggleMenu}>Gallery</Link>
        </li>
        <li className={`phone-nav-item ${activeLink === '/about' ? 'active' : ''}`}>
          <Link to="/about" className="phone-nav-link" onClick={toggleMenu}>About</Link>
        </li> 
        <li className={`phone-nav-item ${activeLink === '/services' ? 'active' : ''}`}>
          <Link to="/services" className="phone-nav-link" onClick={toggleMenu}>Services</Link>
        </li>
        <li className={`phone-nav-item ${activeLink === '/contact' ? 'active' : ''}`}>
          <Link to="/contact" className="phone-nav-link" onClick={toggleMenu}>Contact</Link>
        </li>
      </ul>
    </div>
  );
};

export default PhoneToggle;
