import axios from 'axios';

export function getDataSummary(searchValue) {
  const authToken = "vivek";
  const headers = {
    authorization: authToken,
  };

  // Construct the URL with the searchValue as a query parameter
  const url = `http://localhost:5000?search=${encodeURIComponent(searchValue)}`;

  return axios.get(url, { headers })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      }
      const err = new Error('Invalid response');
      err.data = responseBody;
      throw err;
    })
    .catch((err) => {
      console.error('Error while calling getDataSummary API:', err);
      throw err;
    });
}

export function sendEmail(data) {
  return axios.post('http://localhost:5000/send-email', data)
    .then(response => {
      if (response.data.status === 'success') {
        return response.data;
      }
      const err = new Error('Failed to send email');
      err.data = response.data;
      throw err;
    })
    .catch(err => {
      console.error('Error while sending email:', err);
      throw err;
    });
}
