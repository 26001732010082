import React from 'react';
import './About.css'; // Import your CSS file for styling
import engeenering from '../../images/engeenering.jpeg';
import aboutUs from '../../images/aboutUs.jpeg';
import samplePdf from '../../images/TechnoProfile.pdf'; // Import the placeholder PDF file

const About = () => {
  const handleDownload = () => {
    window.open(samplePdf, '_blank');
  };

  return (
    <div className="about-container">
      <h2 className="about-header">About Us</h2>
      <div className="about-content">
        <div className="about-text">
          <p>
            Technocom Consultants, is a registered Properitorship Firm, founded in the year of September 2009 at Raipur, Chhattisgarh, India, by Surendra Singh Chauhan under Guardianships of Shri Jaipal Singh Chauhan, with professionally qualified engineers having extensive experience in Industrial Operation, Drawing-Designing, Consultancy of basic and detailed Engineering in Civil, Structural, Mechanical, Electrical and automation.
          </p>
          <br />
          <p>
            We have rich experience in Project Management, Procurement Services, Construction Management, Inspection Services and Site Supervision Services in iron-steel, ferro-alloys, foundry, forging, and general engineering industries etc.
          </p>
          <br />
          <p>
            Technocom Consultants today has established itself as an effective firm with proven track record for offering pro-active, personalized and techno commercial services to suit the requirements of the clients.
          </p>
          <br />
          <p>
            The principal reason for the firm’s accelerated growth is the technical expertise of its professionals, who have vast experience and knowledge of latest technologies in their respective fields. The firm has achieved a high level of client appreciation by understanding their requirements and exceeding their expectations. Welcome to Technocom Consultants, where we specialize in delivering comprehensive engineering solutions tailored to meet our client’s unique needs. Our expertise spans across two key areas: Basic and detail Engineering.
          </p>
          <br />
          <p>
            At Technocom Consultants, we believe in the power of innovation and precision. Our team of experienced engineers and industry experts provides high-quality, reliable services that drive success for our clients. Whether you are embarking on a new project or seeking to enhance existing operations, we offer a wide range of engineering solutions designed to meet the most demanding specifications and standards.
          </p>
          <br />
        </div>
        <div className="about-image">
          <img src={aboutUs} alt="About Us" style={{ height: '250px', width: '500px' }} />
        </div>
      </div>

      {/* Clickable "Know More" header */}
      <h2 className="about-header know-more" onClick={handleDownload}>Know more about us</h2>
    </div>
  );
};

export default About;
