// ProductCartDetail.js
import React from 'react';
import StarRating from '../../commponent/RatingStars/RatingStars'; // Import the StarRating component
import './ProductCartDetail.css'; // Import CSS file

const ProductCartDetail = ({ location }) => {
  const { state } = location;
  const product = state && state.product; // Check if state and product exist

  // Check if product is undefined
  if (!product) {
    return <div>No product selected</div>;
  }

  const openProductWebsite = (product) => {
    window.open(product.url, "_blank");
  };

  return (
    <div className="product-cart-detail">
      {/* Left Side - Image */}
      <div className="product-image">
        <img src={product.image} alt={product.title} />
      </div>

      {/* Center Content */}
      <div className="product-details">
        {/* Product Name */}
        <h2>{product.title}</h2>
        
        {/* Star Rating */}
        <div className='star-rating'>
          <StarRating ratingPercentage={product.source_rating} />
        </div>

        <div className='product-price-detail with-shadow'>
          <div className='product-price-detail-content'>
              <div className="product-card__logo" onClick={() => openProductWebsite(product)}>
                  <img src={product.source_logo} alt={product.source_name} className="product-card__logo-image" />
                  <span className="product-card__span">₹{product.source_mrp}</span>
                  <p className="product-card__discount">({product.discount}%off)</p>
              </div>
              <p className="buy-now-text">Buy Now</p> {/* Added Buy Now text */}
          </div>
      </div>
      <div className='product-price-detail with-shadow'>
          <div className='product-price-detail-content'>
              <div className="product-card__logo" onClick={() => openProductWebsite(product)}>
                  <img src={product.source_logo} alt={product.source_name} className="product-card__logo-image" />
                  <span className="product-card__span">₹{product.source_mrp}</span>
                  <p className="product-card__discount">({product.discount}%off)</p>
              </div>
              <p className="buy-now-text">Buy Now</p> {/* Added Buy Now text */}
          </div>
      </div>
      <div className='product-price-detail with-shadow'>
          <div className='product-price-detail-content'>
              <div className="product-card__logo" onClick={() => openProductWebsite(product)}>
                  <img src={product.source_logo} alt={product.source_name} className="product-card__logo-image" />
                  <span className="product-card__span">₹{product.source_mrp}</span>
                  <p className="product-card__discount">({product.discount}%off)</p>
              </div>
              <p className="buy-now-text">Buy Now</p> {/* Added Buy Now text */}
          </div>
      </div>
      <table>
        <li>vivek</li>
      </table>
     

        {/* Product Description */}
        <p>Description: {product.title_variant}</p>

        {/* Additional Details */}
        {/* Add more details as needed */}
      </div>
    </div>
  );
};

export default ProductCartDetail;
