import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import NavigationBar from './commponent/NavigationBar/NavigationBar';
import Home from './commponent/Home/Home';
import About from './commponent/About/About';
import Services from './commponent/Services/Services';
import Contact from './commponent/Contact/Contact';
import Footer from './commponent/Footer/Footer';
import ProductCartDetail from './commponent/ProductCartDetail/ProductCartDetail'; 
import Gallery from './commponent/Galary/Gallery';
import './App.css'

const App = () => {
  return (
    <Router>
      <div>
        <NavigationBar />
        <div className="app-container">
        <Switch> 
          <Route path="/" exact component={Home} />
          <Route path="/about" component={About} />
          <Route path="/gallery" component={Gallery} />
          <Route path="/services" component={Services} />
          <Route path="/contact" component={Contact} />
          <Route path="/cart/detail" component={ProductCartDetail} /> 
        </Switch> 
        <Footer/>
        </div>
      </div>
    </Router>
  );
};

export default App;
