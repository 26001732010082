import React from 'react';
import styled, { css } from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  max-width: 900px;
  margin: 20px auto;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 90%;
  }

  ${(props) =>
    !props.sameOnPhone &&
    css`
      flex-direction: ${(props) => (props.flip ? 'row-reverse' : 'row')};

      @media (max-width: 768px) {
        flex-direction: column;
      }
    `}
`;

const Image = styled.img`
  width: 40%;
  border-radius: 10px;
  margin-left: 20px;

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }

  ${(props) =>
    props.flip &&
    css`
      margin-left: 0;
      margin-right: 20px;

      @media (max-width: 768px) {
        margin-right: 0;
      }
    `}
`;

const Text = styled.div`
  width: 60%;
  padding: 20px;
  align-self: flex-start;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
`;

const RightSideImageLeftSideText = ({ imageSrc, children, flip = false, sameOnPhone = true }) => {
  return (
    <Container flip={flip} sameOnPhone={sameOnPhone}>
      <Text>{children}</Text>
      <Image src={imageSrc} alt="Image" flip={flip} />
    </Container>
  );
};

export default RightSideImageLeftSideText;
