import React from 'react';
import { useHistory } from 'react-router-dom';
import About from '../About/About';
import mobileLogo from '../../images/mobile.png';
import emailLogo from '../../images/email.png';
import RightSideImageLeftSideText from '../NewSevices/NewSevices';
import BasicEng from '../../images/basicEng.jpeg';
import whatsappLogo from '../../images/whatsapp.png';
import DetailEng from '../../images/detailEng.jpeg';
import './Home.css';




const Home = () => {
  const history = useHistory();

  const handleClick = () => {
    history.push('/about');
  };
  const servicesData = [
    {
      header: "Basic Engineering",
      text: "Our comprehensive Basic Engineering services encompass meticulous plant layout planning, meticulously allocating space for crucial steel mill equipment. This includes workshop storage areas for scrap, refractories, ferroalloys, fluxes, electrical substations (indoor/outdoor), circuit breaker and transformer rooms, motor control centers, power distribution boards, automation control rooms (ACPs), maintenance rooms, compressor rooms, hydraulic rooms, laboratory rooms, slag cooling and dumping areas, ladle pre-heaters, ladle repair areas, and utility areas."
    },
    {
      header: "Detailed Engineering",
      text: "We specialize in crafting detailed engineering drawings of civil and structure for all steel mill utilities, including water systems, electrical systems, and automation systems. Our meticulous service considers environmental regulations and meteorological data to ensure adherence to compliances and facilitate optimal functionality"
    },
    
  ];
  const handleWhatsAppClick = () => {
    const phoneNumber = '9863636969';
    const message = 'Hello, I would like to contact you regarding...';
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };

  const handleEmailClick = () => {
    const email = 'support@technocomconsultants.com';
    window.location.href = `mailto:${email}`;
  };

  const handlePhoneClick = () => {
    const phone = '91- 98 6363 6969';
    window.location.href = `tel:${phone}`;
  };
  return (
    <div>
      <div className="backgroundDiv">
        <h2 className="home-header">Welcome to the realm of Technocom Consultants</h2>
        <div>
          <button onClick={handleClick} className="navigate-button">Know Us Well</button>
        </div>
      </div>
      <RightSideImageLeftSideText imageSrc={BasicEng} flip={true} sameOnPhone={false}>
      <h1>{servicesData[0].header}</h1>
      <p>{servicesData[0].text}</p>
      </RightSideImageLeftSideText>
      <RightSideImageLeftSideText imageSrc={DetailEng}>
      <h1>{servicesData[1].header}</h1>
      <p>{servicesData[1].text}</p>
    </RightSideImageLeftSideText>
      <About />
      <h2 className="services-header">Contact Us</h2>
      <div className="container">
        <div className="box" onClick={handlePhoneClick} style={{ cursor: 'pointer' }}>
          <img src={mobileLogo} alt="Mobile" className="icon" />
          <p>+91- 9863636969</p>
        </div>
        <div className="box" onClick={handleEmailClick} style={{ cursor: 'pointer' }}>
          <img src={emailLogo} alt="Email" className="icon" />
          <p>Support@technocomconsultants.com</p>
        </div>
        <div className="box" onClick={handleWhatsAppClick} style={{ cursor: 'pointer' }}>
          <img src={whatsappLogo} alt="WhatsApp" className="icon" />
          <p>9863636969</p>
        </div>
      </div>
      
    </div>
  );
};

export default Home;
