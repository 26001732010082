// Services.js
import React from 'react';
import ProductCard from '../Card/Card';
import BasicEng from '../../images/basicEng.jpeg';
import DetailEng from '../../images/detailEng.jpeg';
import RightSideImageLeftSideText from '../NewSevices/NewSevices';
import './Services.css'; // Import the CSS file
const servicesData = [
  {
    header: "Basic Engineering",
    text: "Our comprehensive Basic Engineering services encompass meticulous plant layout planning, meticulously allocating space for crucial steel mill equipment. This includes workshop storage areas for scrap, refractories, ferroalloys, fluxes, electrical substations (indoor/outdoor), circuit breaker and transformer rooms, motor control centers, power distribution boards, automation control rooms (ACPs), maintenance rooms, compressor rooms, hydraulic rooms, laboratory rooms, slag cooling and dumping areas, ladle pre-heaters, ladle repair areas, and utility areas."
  },
  {
    header: "Detailed Engineering",
    text: "We specialize in crafting detailed engineering drawings of civil and structure for all steel mill utilities, including water systems, electrical systems, and automation systems. Our meticulous service considers environmental regulations and meteorological data to ensure adherence to compliances and facilitate optimal functionality"
  },
  
];
// const servicesData = [
//   {
//     header: "Basic Engineering",
//     text: "Our comprehensive Basic Engineering services encompass meticulous plant layout planning, meticulously allocating space for crucial steel mill equipment. This includes workshop storage areas for scrap, refractories, ferroalloys, fluxes, electrical substations (indoor/outdoor), circuit breaker and transformer rooms, motor control centers, power distribution boards, automation control rooms (ACPs), maintenance rooms, compressor rooms, hydraulic rooms, laboratory rooms, slag cooling and dumping areas, ladle pre-heaters, ladle repair areas, and utility areas."
//   },
//   {
//     header: "Detailed Engineering",
//     text: "We specialize in crafting detailed engineering drawings of civil and structure for all steel mill utilities, including water systems, electrical systems, and automation systems. Our meticulous service considers environmental regulations and meteorological data to ensure adherence to compliances and facilitate optimal functionality"
//   },
  
// ];

const Services = () => {
  return (
    <div className="services-wrapper">
      <div>
        <h2 className="services-header">Our Services</h2>
        {/* <div className="services-container">
          {servicesData.map((service, index) => (
            <ProductCard key={index} header={service.header} text={service.text} />
          ))}
        </div> */
        }
        <RightSideImageLeftSideText imageSrc={BasicEng} flip={true} sameOnPhone={false}>
      <h1>{servicesData[0].header}</h1>
      <p>{servicesData[0].text}</p>
      </RightSideImageLeftSideText>
      <RightSideImageLeftSideText imageSrc={DetailEng}>
      <h1>{servicesData[1].header}</h1>
      <p>{servicesData[1].text}</p>
    </RightSideImageLeftSideText>
      </div>
    </div>
  );
}

export default Services;
