// StarRating.js
import React from 'react';

const StarRating = ({ ratingPercentage }) => {
  // Convert rating percentage to number of filled stars
  const filledStars = Math.round((ratingPercentage / 100) * 5 * 2) / 2;
  // Generate filled and empty star icons
  const stars = [];
  for (let i = 0; i < 5; i++) {
    if (i < filledStars) {
      stars.push(<span key={i} className="star-filled">★</span>);
    } else {
      stars.push(<span key={i} className="star-empty">★</span>);
    }
  }
  return (
    <div className="star-rating">
      {stars}
    </div>
  );
};

export default StarRating;
